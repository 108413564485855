var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('label', {
    attrs: {
      "for": "filters"
    }
  }, [_vm._v("Filters")]), _vm.assetGroupsLoading.list || _vm.entitiesLoading.list || _vm.assetsLoading.list ? _c('Spinner', {
    attrs: {
      "size": "sm"
    }
  }) : _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    style: _vm.filterType !== 'all' ? 'max-width: 110px; min-width: 100px' : 'max-width: 200px',
    attrs: {
      "id": "filterType",
      "options": [{
        value: 'asset',
        label: 'Asset'
      }, {
        value: 'portfolio',
        label: 'Portfolio'
      }, {
        value: 'entity',
        label: 'Entity'
      }, {
        value: 'all',
        label: 'All'
      }],
      "type": "select",
      "alt": ""
    },
    on: {
      "input": _vm.onSelectFilterType
    },
    model: {
      value: _vm.filterType,
      callback: function callback($$v) {
        _vm.filterType = $$v;
      },
      expression: "filterType"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_vm.filterType === 'asset' ? _c('FormItem', {
    attrs: {
      "id": "filter",
      "options": _vm.assetOptions,
      "type": "select"
    },
    on: {
      "input": _vm.onSelectAsset
    },
    model: {
      value: _vm.filters.assetId,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "assetId", $$v);
      },
      expression: "filters.assetId"
    }
  }) : _vm.filterType === 'portfolio' ? _c('FormItem', {
    attrs: {
      "id": "filter",
      "options": _vm.assetGroupOptions,
      "type": "select"
    },
    on: {
      "input": _vm.onSelectPortfolio
    },
    model: {
      value: _vm.assetGroupId,
      callback: function callback($$v) {
        _vm.assetGroupId = $$v;
      },
      expression: "assetGroupId"
    }
  }) : _vm.filterType === 'entity' ? _c('FormItem', {
    attrs: {
      "id": "filter",
      "options": _vm.entityOptions,
      "type": "select"
    },
    on: {
      "input": _vm.onSelectEntity
    },
    model: {
      value: _vm.filters.entityId,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "entityId", $$v);
      },
      expression: "filters.entityId"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "col d-flex justify-content-end align-items-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary mt-4 mr-3",
    attrs: {
      "data-cy": "new-asset",
      "to": {
        name: 'automation-create'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus fa-fw"
  }), _vm._v(" New Automation")])], 1)]), _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.accounts,
      "total": _vm.accountsTotal,
      "loading": _vm.loading,
      "i": "",
      "columns": _vm.columns,
      "filters": _vm.filters,
      "page-size": 20,
      "fetch-items": _vm.refreshAccounts,
      "other-params": _vm.otherParams,
      "default-sort-key": "uploadedAt",
      "default-sort-direction": -1
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [data.assetId ? _c('router-link', {
          staticClass: "font-w600 d-block text-capitalize",
          attrs: {
            "target": "_blank",
            "to": {
              name: 'asset-accounts-overview',
              params: {
                id: data.assetId,
                accountId: data._id
              }
            }
          }
        }, [_c('UtilityTypeIcon', {
          attrs: {
            "icon-class": "mr-1",
            "type": data.type,
            "text": false
          }
        }), _vm._v(" " + _vm._s(data.name))], 1) : _vm._e()];
      }
    }, {
      key: "automation",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return _vm._l(data.automation, function (automation) {
          return _c('div', {
            key: automation._id
          }, [_c('div', [_vm._v(_vm._s(automation.service) + " (Last collected: " + _vm._s(_vm._f("date")(automation.lastCollection.collectedAt, 'Do MMM YYYY HH:mm:ss')) + ")")])]);
        });
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var _data$automation;

        var data = _ref3.data;
        return [(_data$automation = data.automation) !== null && _data$automation !== void 0 && _data$automation.length ? _c('router-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "to": {
              name: 'automation-manage',
              params: {
                id: data.automation[0]._id
              }
            }
          }
        }, [_vm._v("Manage")]) : _c('router-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "to": {
              name: 'automation-create',
              query: {
                accountId: data._id
              }
            }
          }
        }, [_vm._v("Manage")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }