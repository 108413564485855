<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <label for="filters" class="">Filters</label>
        <Spinner v-if="assetGroupsLoading.list || entitiesLoading.list || assetsLoading.list" size="sm" />
        <div v-else class="input-group">
          <FormItem
            id="filterType"
            v-model="filterType"
            :options="[
              { value: 'asset', label: 'Asset' },
              { value: 'portfolio', label: 'Portfolio' },
              { value: 'entity', label: 'Entity' },
              { value: 'all', label: 'All' }
            ]"
            type="select"
            alt
            :style="filterType !== 'all' ? 'max-width: 110px; min-width: 100px' : 'max-width: 200px'"
            @input="onSelectFilterType"
          />
          <div class="input-group-append">
            <FormItem
              v-if="filterType === 'asset'"
              id="filter"
              v-model="filters.assetId"
              :options="assetOptions"
              type="select"
              @input="onSelectAsset"
            />
            <FormItem
              v-else-if="filterType === 'portfolio'"
              id="filter"
              v-model="assetGroupId"
              :options="assetGroupOptions"
              type="select"
              @input="onSelectPortfolio"
            />
            <FormItem
              v-else-if="filterType === 'entity'"
              id="filter"
              v-model="filters.entityId"
              :options="entityOptions"
              type="select"
              @input="onSelectEntity"
            />
          </div>
        </div>
      </div>

      <div class="col d-flex justify-content-end align-items-center">
        <router-link data-cy="new-asset" class="btn btn-primary mt-4 mr-3" :to="{ name: 'automation-create' }"
          ><i class="fa fa-plus fa-fw"></i> New Automation</router-link
        >
      </div>
    </div>
    <div class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <DataTable
          :items="accounts"
          :total="accountsTotal"
          :loading="loading"
          i
          :columns="columns"
          :filters="filters"
          :page-size="20"
          :fetch-items="refreshAccounts"
          :other-params="otherParams"
          default-sort-key="uploadedAt"
          :default-sort-direction="-1"
        >
          <template v-slot:name="{ data }">
            <router-link
              v-if="data.assetId"
              class="font-w600 d-block text-capitalize"
              target="_blank"
              :to="{ name: 'asset-accounts-overview', params: { id: data.assetId, accountId: data._id } }"
              ><UtilityTypeIcon icon-class="mr-1" :type="data.type" :text="false" /> {{ data.name }}</router-link
            >
          </template>

          <template v-slot:automation="{ data }">
            <div v-for="automation in data.automation" :key="automation._id">
              <div>{{ automation.service }} (Last collected: {{ automation.lastCollection.collectedAt | date('Do MMM YYYY HH:mm:ss') }})</div>
            </div>
          </template>

          <template v-slot:actions="{ data }">
            <router-link
              v-if="data.automation?.length"
              class="btn btn-primary"
              :to="{ name: 'automation-manage', params: { id: data.automation[0]._id } }"
              >Manage</router-link
            >
            <router-link v-else class="btn btn-primary" :to="{ name: 'automation-create', query: { accountId: data._id } }">Manage</router-link>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { throttle } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';
import FormItem from '@/components/FormItem';
import Spinner from '@/components/Spinner';
import UtilityTypeIcon from '@/components/UtilityTypeIcon';

export default {
  name: 'AutomationMetersList',
  components: {
    DataTable,
    FormItem,
    Spinner,
    UtilityTypeIcon
  },
  data() {
    return {
      loading: true,
      filterType: 'all',
      filters: {
        assetId: null,
        entityId: null
      },
      assetGroupId: null,
      otherParams: {
        $multi: true,
        $populate: 'asset,automation'
      }
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      accountsTotal: 'account/totalAccounts',
      assets: 'asset/assets',
      assetGroups: 'assetGroup/assetGroups',
      entities: 'entity/entities',
      accountsLoading: 'account/loadingAction',
      assetsLoading: 'asset/loadingAction',
      entitiesLoading: 'entity/loadingAction',
      assetGroupsLoading: 'assetGroup/loadingAction',
      automationLoading: 'automation/loadingAction'
    }),
    columns() {
      return [
        { name: 'Name', code: 'name', search: true },
        { name: 'MPAN', code: 'meterPointNumber', search: true },
        { name: 'Serial Number', code: 'meterSerialNumber', search: true },
        { name: 'Asset', code: 'asset.siteName' },
        { name: 'Automation', code: 'automation', noSort: true },
        { name: '', code: 'actions', noSort: true }
      ];
    },
    assetOptions() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets.map(a => ({
        label: a.siteName,
        value: a._id
      }));
    },
    entityOptions() {
      const entities = [...this.entities];

      entities.sort((a, b) => a.legalName.localeCompare(b.legalName));

      return entities.map(e => ({
        label: e.legalName,
        value: e._id
      }));
    },
    assetGroupOptions() {
      const assetGroups = [...this.assetGroups];

      assetGroups.sort((a, b) => a.name.localeCompare(b.name));

      return assetGroups.map(a => ({
        label: a.name,
        value: a._id
      }));
    }
  },
  async mounted() {
    await Promise.all([
      this.listAssets({ data: { params: { $limit: 100000 } } }),
      this.listEntities({ data: { params: { $limit: 100000 } } }),
      this.listAssetGroups({ data: { params: { $limit: 100000 } } })
    ]);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      listAssets: 'asset/list',
      listEntities: 'entity/list',
      listAssetGroups: 'assetGroup/list',
      listAssetGroupAssets: 'assetGroup/listAssets',
      removeAutomation: 'automation/remove'
    }),
    refreshAccounts: throttle(async function db(params) {
      const filters = Object.keys(this.filters).reduce((acc, curr) => {
        if (this.filters[curr]) {
          acc[curr] = this.filters[curr];
        }
        return acc;
      }, {});

      const defaultParams = {
        data: {
          params: {
            ...this.otherParams,
            ...filters,
            $limit: 20,
            $skip: 0,
            $sort: 'uploadedAt:-1'
          }
        }
      };

      this.loading = true;
      await this.listAccounts(params || defaultParams);
      this.loading = false;
    }, 500),
    async onSelectPortfolio(assetGroupId) {
      const assets = await this.listAssetGroupAssets({ id: assetGroupId });

      this.filters = {
        assetId: assets.map(a => a._id).join(','),
        entityId: null
      };
    },
    async onSelectAsset() {
      this.filters.entityId = null;
    },
    async onSelectEntity() {
      this.filters.assetId = null;
    },
    onSelectFilterType() {
      if (this.filterType === 'all') {
        this.filters = {
          assetId: null,
          entityId: null
        };

        this.assetGroupId = null;
      }
    }
  }
};
</script>
